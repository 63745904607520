import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PropTypes from '+/instances/propTypesInstance';
import { SetInitialPreferences } from '+/components/SetInitialPreferences';
import { MainActionFeed } from '+/components/MainActionFeed';
import { Room } from '+/components/Room';
import { Account } from '+/components/Account';
import { path, getPathPermission } from '+/utils/pathFinder';
import TextReader from '+/utils/TextReader';
import defaultRedirectCalculator, {
  deleteLandingPathIfOnIt,
  setLandingPath,
} from '+/utils/defaultRedirectCalculator';

import { AdminRouter } from '../AdminRouter';
import { RequestPasswordReset } from '../Password/RequestPasswordReset';
import { ResetPassword } from '../Password/ResetPassword';
import { ResetPasswordConfirmation } from '../Password/ResetPasswordConfirmation';
import { Welcome } from '../Welcome';
import { Settings } from '../Settings';
import BaseLayout, { withBaseLayout } from '../Layouts/Base.layout';
import BareLayout, { withBareLayout } from '../Layouts/Bare.layout';
import { SignOut } from '../SignOut';

const { t } = new TextReader('welcome.signUp');

export const AppRouter = props => {
  const { currentUser, location } = props;
  const redirectToSignInOrSignUp =
    currentUser.isEmpty() &&
    !document.cookie.includes('fan-session') &&
    !['any', 'loggedOut'].includes(getPathPermission(location.pathname));
  if (!window.thl) {
    window.thl = {};
  }

  window.thl.history = props.history;

  const defaultRedirect = defaultRedirectCalculator(currentUser);
  deleteLandingPathIfOnIt();
  if (redirectToSignInOrSignUp) {
    setLandingPath();
  }

  return (
    <Switch>
      <Redirect exact path="/" to={path('home')} />
      {redirectToSignInOrSignUp && (
        <Redirect
          to={document.cookie.includes('fan-signed-in') ? path('signIn') : path('signUp')}
        />
      )}
      <Route
        exact
        path={path('privacy')}
        render={() => {
          window.location = t('privacyLinkUrl');
          return null;
        }}
      />
      <Route
        exact
        path={path('terms')}
        render={() => {
          window.location = t('termsLinkUrl');
          return null;
        }}
      />
      <Route path={path('admin.index')} component={withBaseLayout(AdminRouter)} />
      <Route exact path={path('setInitialPreferences')}>
        {currentUser.needsToSetPreferences() ? (
          <BareLayout forcePlainHeader flushToFooter>
            <SetInitialPreferences />
          </BareLayout>
        ) : (
          <Redirect to={path('home')} />
        )}
      </Route>
      <Route
        exact
        path={[
          path('home'),
          path('campaigns.action', {
            actionType: ':actionType',
            actionId: ':actionId',
            campaignId: ':campaignId',
          }),
          path('action', { actionType: ':actionType', actionId: ':actionId' }),
        ]}
      >
        {currentUser.needsToSetPreferences() ? (
          <Redirect to={path('setInitialPreferences')} />
        ) : (
          <BaseLayout>
            <MainActionFeed />
          </BaseLayout>
        )}
      </Route>
      <Route exact path={path('notifications')} component={withBareLayout(Settings)} />
      <Route exact path={path('preferences')} component={withBareLayout(Settings)} />
      <Route exact path={path('account')} component={withBaseLayout(Account)} />
      <Route
        exact
        path={path('resetPasswordRequest')}
        component={withBareLayout(RequestPasswordReset)}
      />
      <Route
        exact
        path={path('resetPasswordRequested')}
        component={withBareLayout(ResetPasswordConfirmation)}
      />
      <Route
        exact
        path={path('resetPasswordToken', { token: ':token' })}
        component={withBareLayout(ResetPassword)}
      />
      <Route exact path={path('signOut')} component={SignOut} />
      {currentUser.isLoaded() &&
        [path('signIn'), path('signUp')].includes(window.location.pathname.replace(/\/$/, '')) && (
          // eslint-disable-next-line indent
          <Redirect to={defaultRedirect} />
          // eslint-disable-next-line indent
        )}
      <Route exact path={path('signIn')} component={withBareLayout(Welcome)} />
      <Route exact path={path('signUp')} component={withBareLayout(Welcome)} />
      <Route
        exact
        path={path('room.action', {
          roomPath: ':roomPath',
          actionType: ':actionType',
          actionId: ':actionId',
        })}
        component={withBareLayout(Room)}
      />
      <Route
        exact
        path={path('room.campaignAction', {
          roomPath: ':roomPath',
          actionType: ':actionType',
          actionId: ':actionId',
          campaignId: ':campaignId',
        })}
        component={withBareLayout(Room)}
      />
      <Route
        path={path('room.show', { roomPath: ':roomPath' })}
        component={withBareLayout(Room)}
      />
      <Redirect to={path('signIn')} />
    </Switch>
  );
};

AppRouter.propTypes = {
  currentUser: PropTypes.models.User.isRequired,
  location: PropTypes.location.isRequired,
  history: PropTypes.history.isRequired,
};
